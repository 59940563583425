import _ from 'underscore';
import { useState, useDebugValue } from 'react';
import moment from 'moment';
import XDate from 'xdate';
import { datadogLogs } from '~/lib/datadog';

if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

const setCookie = (name, value, days) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

const getCookie = (cname) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

const sumArray = (arr) => arr.reduce((a, b) => a + b, 0);

const unroundedAvg = (arr) => sumArray(arr) / arr.length;

const avg = (arr) => Math.round(sumArray(arr) / arr.length);

const roundToOneDecimal = (num) => {
  let numericNum = num;
  if (typeof num === 'string') {
    numericNum = parseFloat(num);
  }
  return parseFloat(numericNum.toFixed(1));
};

const getPercent = (a, b) => Math.floor((a / b) * 100);

const getPercentRounded = (a, b) => Math.round((a / b) * 100);

//  Use locks if we want to ensure no race condition happens where
//  a loader is cleared in some other async process when another
//  async process still wants it to show;
const loaderLocks = {};

const showLoader = (getLock = false, preventAppInteraction = false) => {
  const loader = document.querySelector('#loader');

  loader.style.display = 'block';
  loader.classList.remove('prevent-app-interaction');
  if (preventAppInteraction) loader.classList.add('prevent-app-interaction');

  if (getLock) {
    const lockId = Date.now();
    loaderLocks[lockId] = true;
    return lockId;
  }
};

const hideLoader = (lockId = null) => {
  if (lockId) delete loaderLocks[lockId];
  if (Object.keys(loaderLocks).length) return;

  const loader = document.querySelector('#loader');

  loader.style.display = 'none';
  loader.classList.remove('prevent-app-interaction');
};

const parseApiError = (xhr) => {
  let errorMsg;

  if (xhr.status === 403) {
    errorMsg = 'You do not have access to complete this operation.';
  } else if (xhr.status === 409) {
    return;
  } else if (xhr.status === 500) {
    errorMsg = 'An unexpected error occurred. Please try again.';
  } else if (xhr.responseText) {
    try {
      const errorResponse = JSON.parse(xhr.responseText);

      if (errorResponse.error && _.isArray(errorResponse.error)) {
        return errorResponse.error[0];
      }

      errorMsg = errorResponse.message && errorResponse.message.replace(/\\n/g, '\n');
    } catch (e) {
      errorMsg = 'An unexpected error occurred. Please try again.';
    }
  }

  return errorMsg;
};

const handleApiError = (callback, useWRModal) => (xhr) => {
  hideLoader();

  const errorDisplayMechanism = useWRModal ? WRModals.alert : alert;

  const errorMsg = parseApiError(xhr);
  if (errorMsg) errorDisplayMechanism(errorMsg);

  if (typeof callback === 'function') {
    callback(xhr);
  }
};

const apiCall = (type, providedUrl, rawData, successCallback, errorCallback, namespace) => {
  const validatedNamespace = _.isString(namespace) ? namespace : '/api/';
  const url = validatedNamespace + providedUrl;
  const contentType = 'application/json';

  let data;
  if (rawData) {
    data = JSON.stringify(rawData);
  }

  return $.ajax({
    url,
    type,
    data,
    contentType,
    success: (result) => {
      if (successCallback) {
        successCallback(result);
      }
    },
    error: (xhr, status) => {
      hideLoader();
      if (errorCallback) {
        errorCallback(xhr);
      } else {
        handleApiError()(xhr);
      }
    },
  });
};

const apiGet = (url, data, successCallback, errorCallback, namespace) =>
  apiCall('GET', url, data, successCallback, errorCallback, namespace);

const apiPost = (url, data, successCallback, errorCallback, namespace) =>
  apiCall('POST', url, data, successCallback, errorCallback, namespace);

const apiPatch = (url, data, successCallback, errorCallback, namespace) =>
  apiCall('PATCH', url, data, successCallback, errorCallback, namespace);

const apiPut = (url, data, successCallback, errorCallback, namespace) =>
  apiCall('PUT', url, data, successCallback, errorCallback, namespace);

const apiDelete = (url, data, successCallback, errorCallback, namespace) =>
  apiCall('DELETE', url, data, successCallback, errorCallback, namespace);

/**
 * Get properly formatted uri for a get request with params. Rails automatically parses arrays in the format
 * blah_ids[]=1&blah_ids[]=2.
 */
const formatApiGetUri = (uri, params) => {
  let output = `${uri}?`;
  _.each(_.keys(params), (key) => {
    const value = params[key];
    if (Array.isArray(value)) {
      _.each(value, (item) => {
        output += `${key}[]=${item}&`;
      });
    } else {
      output += `${key}=${encodeURIComponent(value)}&`;
    }
  });
  // Remove last char - it'll be ? or &
  output = output.slice(0, -1);
  return output;
};

const updateTableSort = () => {
  $('.tablesorter').trigger('update');
};

$(document).ready(() => {
  $(document).click((e) => {
    $('.nav-dropdown-onclick').parent().find('ul').hide();
    $('.nav-dropdown-onclick-2').parent().find('ul').hide();
    $('.my-dropdown-list').hide();
    const el = $(e.target).parents('.my-dropdown-sticky');
    if (el.length > 0) {
      el.find('ul').show();
    }
  });

  $('.nav-dropdown-onclick').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    const node = $(this).parent().find('ul');
    if (node.css('display') === 'none') {
      $('.nav-dropdown-onclick').parent().find('ul').hide();
      $('.my-dropdown-list').hide();
      node.css('display', 'block');
    } else {
      node.css('display', 'none');
    }
  });

  $('#signout-link').click((e) => {
    e.preventDefault();
    $('#signout-form').submit();
  });

  $.tablesorter.addParser({
    id: 'dataSorterNumeric',
    is() {
      // return false so this parser is not auto detected
      return false;
    },
    format(s, table, cell, cellIndex) {
      // get data attributes from $(cell).attr('data-sort-value');
      // check specific column using cellIndex
      return $(cell).attr('data-sort-value');
    },
    // set type, either numeric or text
    type: 'numeric',
  });
});

const inIframe = function () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const handleIFrameLogin = () => {
  addEventListener(
    'message',
    (event) => {
      if (event.data === 'close_self_jlk233') {
        close();
      }
    },
    false,
  );
};

const randomString = (length) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

// Pretty slow, replace if that's ever an issue
const guid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

// TODO: Refactor date logic so ids aren't encoded everywhere
const getDateWithOffset = (startDate, offset, offsetUnits) => {
  switch (offsetUnits) {
    case 0:
      return new XDate(startDate).addDays(offset);
    case 1:
      return new XDate(startDate).addWeeks(offset);
    case 2:
      return new XDate(startDate).addMonths(offset);
    case 3:
      return new XDate(startDate).addYears(offset);
    default:
      return new XDate(startDate).addDays(offset);
  }
};

// TODO: Refactor date logic so ids aren't encoded everywhere
const getDateUnitDisplayText = (id) => {
  switch (id) {
    case 0:
      return 'Days';
    case 1:
      return 'Weeks';
    case 2:
      return 'Months';
    case 3:
      return 'Years';
    default:
      return 'Days';
  }
};

const getDescendantIdsFromFolder = (folder) => {
  let ret = [];

  ret.push(folder.id);

  _.each(folder.courses, (course) => {
    ret.push(course.id);
    _.each(course.courseSections, (section) => {
      ret.push(section.id);
      _.each(section.courseSectionTasks, (task) => {
        ret.push(task.id);
      });
    });
  });

  _.each(folder.trainingWorkflows, (workflow) => {
    ret.push(workflow.id);
  });

  _.each(folder.trainingSeries, (series) => {
    ret.push(series.id);
  });

  _.each(folder.universities, (university) => {
    ret.push(university.id);
  });

  _.each(folder.challenges, (challenge) => {
    ret.push(challenge.id);
  });

  _.each(folder.resources, (resource) => {
    ret.push(resource.id);
  });

  _.each(folder.trainingClasses, (trainingClass) => {
    ret.push(trainingClass.id);
  });

  _.each(folder.topLevelContents, (content) => {
    ret.push(content.id);
  });

  _.each(folder.academyCertifications ?? [], (certification) => {
    ret.push(certification.id);
  });

  _.each(folder.academyPaths ?? [], (path) => {
    ret.push(path.id);
  });

  _.each(folder.academyCollections ?? [], (collection) => {
    ret.push(collection.id);
  });

  _.each(folder.academyEvents ?? [], (event) => {
    ret.push(event.id);
  });

  _.each(folder.subfolders, (subfolder) => {
    ret.push(subfolder.id);
    ret = ret.concat(getDescendantIdsFromFolder(subfolder));
  });

  return ret;
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.removeNbsps = function () {
  return this.replace(/\u00a0/g, ' ');
};

const preventScrollPropogation = function ($element) {
  $element.on('DOMMouseScroll mousewheel', function (ev) {
    const $this = $(this);
    const { scrollTop, scrollHeight } = this;
    const height = $this.innerHeight();
    const delta = ev.type === 'DOMMouseScroll' ? ev.originalEvent.detail * -40 : ev.originalEvent.wheelDelta;
    const up = delta > 0;

    const prevent = function () {
      ev.stopPropagation();
      ev.preventDefault();
      ev.returnValue = false;
      return false;
    };

    if (!up && -delta > scrollHeight - height - scrollTop) {
      // Scrolling down, but this will take us past the bottom.
      $this.scrollTop(scrollHeight);
      return prevent();
    }
    if (up && delta > scrollTop) {
      // Scrolling up, but this will take us past the top.
      $this.scrollTop(0);
      return prevent();
    }
  });
};

const goToTaskHelper = (taskId, prependToUrl, appendToUrl) => {
  let url = `/admin/tasks/${taskId}${appendToUrl}`;
  if (prependToUrl) {
    url = prependToUrl + taskId + appendToUrl;
  }

  window.location.href = url;
};

const goToTask = (taskId, taskObjects, prependToUrl, appendToUrl) => {
  const objectIds = taskObjects.map((object) => object.id);
  let ret;
  if (ChangesPendingStore.isUploadRequired()) {
    ret = confirm(
      "You have recorded a video but have not uploaded it (or upload is currently in progress). Click 'OK' if you want to leave this page, otherwise click 'Cancel'.",
    );
    if (ret) {
      ChangesPendingStore.resetUploadRequired();
      goToTaskHelper(taskId, prependToUrl, appendToUrl);
    }
  } else if (ChangesPendingStore.areChangesPending(objectIds)) {
    ret = confirm(
      "You have unsaved changes on this page. Click 'OK' if you want to leave this page, otherwise click 'Cancel'.",
    );
    if (ret) {
      ChangesPendingStore.reset();
      goToTaskHelper(taskId, prependToUrl, appendToUrl);
    }
  } else {
    goToTaskHelper(taskId, prependToUrl, appendToUrl);
  }
};

const setToContentHeight = (el) => {
  // Need to check if element is not null, otherwise, console logs lots of errors
  if (el) {
    const $el = $(el);
    const paddingOffset = $el.innerHeight() - $el.height();

    const height = Math.max(el.scrollHeight - paddingOffset, 16);
    $el.height(0).height(height);
  }
};

const enableAutoExpand = ($textarea) => {
  /* Hacking around fields that are in modals */
  setTimeout(() => {
    setToContentHeight($textarea.get(0));
  }, 10);

  $textarea.on('change keyup keydown paste cut', () => {
    setToContentHeight(this);
  });
};

const copyTextToClipboard = (text, context = null) => {
  const textArea = document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;

  if (context) {
    context.parentNode.insertBefore(textArea, context);
  } else {
    document.body.appendChild(textArea);
  }

  textArea.select();

  try {
    const successful = document.execCommand('copy');
    const msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.log('Copy failed');
  }

  textArea.parentNode.removeChild(textArea);
};

const toTitleCase = (str) => {
  if (str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  }
  return '';
};

const stripTags = (string) => {
  const div = document.createElement('div');
  div.innerHTML = string;
  return div.textContent || div.innerText || '';
};

const isValidEmailAddress = (email) => /^[a-zA-Z0-9_.+'-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);

const isValidUrl = (url, validProtocols = ['https']) => {
  try {
    const newUrl = new URL(url);

    if (validProtocols.length > 0) {
      return validProtocols.includes(newUrl.protocol.replace(':', ''));
    }
  } catch (e) {
    return false;
  }

  return true;
};

const ViewportUtils = {
  viewportHeight() {
    return Math.max(document.documentElement.clientHeight, 0);
  },

  viewportWidth() {
    return Math.max(document.documentElement.clientWidth, 0);
  },
};

const URIUtils = {
  getParameterByName(name, providedUrl) {
    const url = providedUrl || window.location.href;
    const newName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  // If you're tempted to use this when you're composing a complete query string all at once
  // please consider using URLSearchParams instead: `new URLSearchParams({ foo: "a value", bar: "xyz" }).toString()`
  addParamToUrl(url, name, value) {
    name = encodeURIComponent(name);
    value = encodeURIComponent(value);
    if (url.includes('?')) {
      return `${url}&${name}=${value}`;
    }
    return `${url}?${name}=${value}`;
  },
};

const UsersAndGroupsHTMLUtils = {
  parseTypeAndId(item) {
    const type = item.id.split('_')[0];
    const id = item.id.split('_')[1];
    return { type, id };
  },
};

const DOMUtils = {
  setFocusToEndWithDelay(node, str = '') {
    if (node) {
      setTimeout(() => {
        $(node).focus();
        node.setSelectionRange(str.length, str.length);
      }, 500);
    }
  },
};

const ArrayUtils = {
  toTitleCase(arr) {
    return _.map(arr || [], (el) => toTitleCase(el.toLowerCase()));
  },
};

const ObjectUtils = {
  // generates a new object that contains what was changed from obj1 to obj2
  // does not find key/value pairs that are present in obj1 but not in obj2
  diff(obj1, obj2) {
    const diffObj = {};

    _.each(obj2, (value, key) => {
      if (!obj1.hasOwnProperty(key)) {
        diffObj[key] = value;
      } else if (!!value && value.constructor === Object) {
        const nestedDiffObj = this.diff(obj1[key], value);
        if (nestedDiffObj) diffObj[key] = nestedDiffObj;
      } else if (!!value && value.constructor === Array) {
        // not a deep difference check
        const nestedDiffArr = _.difference(obj1[key], value);
        if (!_.isEmpty(nestedDiffArr)) diffObj[key] = nestedDiffArr;
      } else if (obj1[key] !== value) {
        diffObj[key] = value;
      }
    });

    return _.isEmpty(diffObj) ? null : diffObj;
  },

  keysAndNestedKeys(obj) {
    const keys = [];
    _.each(obj, (value, key) => {
      keys.push(key);

      if (!!value && value.constructor === Object) {
        keys.push(...this.keysAndNestedKeys(value));
      }
    });

    return keys;
  },
};

const removeTooltipPopups = function () {
  /*
    workarounds: forEach isn't supported in ie11
  */
  const potentialTooltips = document.querySelectorAll('.ui-tooltip');
  for (let i = 0; i < potentialTooltips.length; i++) {
    potentialTooltips[i].remove();
  }
};

// returns a matchMedia object:
// const matchMedia = matchMedia('max', '768');
// matchMedia.matches = true if '(max-width: 768px)' media query matches
const getMatchMedia = (minMax, width) => matchMedia(`(${minMax}-width: ${width}px)`);

// adds label for react devtools: https://github.com/facebook/react/issues/16474#issuecomment-582225302
const useStateWithLabel = function useStateWithLabel(initialValue, name) {
  const [value, setValue] = useState(initialValue);
  useDebugValue(`${name}: ${value}`);
  return [value, setValue];
};

const setMomentLocale = () => {
  const userLanguageCode =
    getCookie(APP_CONSTANTS.USER_LANGUAGE_COOKIE) ||
    window.getCurrentUser().enterprise.language ||
    APP_CONSTANTS.DEFAULT_LANGUAGE;
  moment.locale(userLanguageCode);
};

const basename = (path) => path.replace(/.*\//, '');

const MixPanel = {
  track(event, extraProps) {
    window.mixpanel?.track(event, this.getProperties(extraProps), { transport: 'sendBeacon' });
  },
  trackLinks(selector, event, extraProps) {
    window.mixpanel?.track_links(selector, event, this.getProperties(extraProps));
  },
  getProperties(extraProps) {
    let props = _.clone(extraProps) || {};
    props = _.extend(props, {
      viewport_width: window.innerWidth,
      viewport_height: window.innerHeight,
    });
    const user = (window.getCurrentUser || window.getExternalUser)?.();
    if (user) {
      return _.extend(props, {
        title: document.title,
        url: window.location.href,
        path: window.location.pathname,
        email: user.email,
        name: user.name,
        is_admin: !!user.admin,
        is_paying: !!user.enterprise.is_paying,
        enterprise_name: user.enterprise.name,
        enterprise_id: user.enterprise.id,
        user_id: user.id,
      });
    }
    return props;
  },
};

const safetyNet = {
  ArrayUtils,
  DOMUtils,
  MixPanel,
  ObjectUtils,
  URIUtils,
  UsersAndGroupsHTMLUtils,
  ViewportUtils,
  apiCall,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut,
  avg,
  basename,
  copyTextToClipboard,
  enableAutoExpand,
  formatApiGetUri,
  getCookie,
  getDateUnitDisplayText,
  getDateWithOffset,
  getDescendantIdsFromFolder,
  getMatchMedia,
  getPercent,
  getPercentRounded,
  goToTask,
  guid,
  handleApiError,
  handleIFrameLogin,
  hideLoader,
  inIframe,
  isValidEmailAddress,
  isValidUrl,
  parseApiError,
  preventScrollPropogation,
  randomString,
  removeTooltipPopups,
  roundToOneDecimal,
  setCookie,
  setMomentLocale,
  showLoader,
  stripTags,
  sumArray,
  toTitleCase,
  unroundedAvg,
  updateTableSort,
  useStateWithLabel,
};

const windowFunctionErrorLogger =
  (functionName, func) =>
  (...args) => {
    const e = new Error();
    datadogLogs.logger.warn(`global function used: ${functionName}`, e);
    return func.apply(this, [...args]);
  };

Object.entries(safetyNet).forEach(([functionName, func]) => {
  // this is an anti pattern, we should do explicit exports / imports
  window[functionName] = windowFunctionErrorLogger(functionName, func);
});

const getDefaultFont = () => 'Inter';

const isMultiSession = (trainingClass) => trainingClass.sessionsType === APP_CONSTANTS.SESSIONS_TYPES.MULTIPLE;

// gradientStartColor and gradientEndColor: objects containing r, g, b for red, green, and blue
// percent: percent toward the end color, 0-100
// returns an object containing r, g, b
const getMixedColorWithPercent = (gradientStartColor, gradientEndColor, percent) => {
  const weightedAverageHelper = (num1, num2, p) => ((100 - p) * num1 + p * num2) / 100;

  return {
    r: weightedAverageHelper(gradientStartColor.r, gradientEndColor.r, percent),
    g: weightedAverageHelper(gradientStartColor.g, gradientEndColor.g, percent),
    b: weightedAverageHelper(gradientStartColor.b, gradientEndColor.b, percent),
  };
};

// this is the pattern we want to use -- we should export all these functions,
// instead of relying on them being on the window
export {
  ArrayUtils,
  DOMUtils,
  MixPanel,
  ObjectUtils,
  URIUtils,
  UsersAndGroupsHTMLUtils,
  ViewportUtils,
  apiCall,
  apiDelete,
  apiGet,
  apiPost,
  apiPatch,
  apiPut,
  avg,
  basename,
  copyTextToClipboard,
  enableAutoExpand,
  formatApiGetUri,
  getCookie,
  getDateUnitDisplayText,
  getDateWithOffset,
  getDefaultFont,
  getDescendantIdsFromFolder,
  getMatchMedia,
  getPercent,
  getPercentRounded,
  goToTask,
  guid,
  handleApiError,
  handleIFrameLogin,
  hideLoader,
  inIframe,
  isMultiSession,
  isValidEmailAddress,
  isValidUrl,
  parseApiError,
  preventScrollPropogation,
  randomString,
  removeTooltipPopups,
  roundToOneDecimal,
  setCookie,
  setMomentLocale,
  showLoader,
  stripTags,
  sumArray,
  toTitleCase,
  unroundedAvg,
  updateTableSort,
  useStateWithLabel,
  getMixedColorWithPercent,
};
